@tailwind base
@tailwind components

@font-face
  font-family: "Proxima Nova"
  src: local('Proxima Nova,Proxima Nova Rg:style=Light')
  src: local('Proxima Nova Light')
  src: local('proxima-nova-light')
  src: local('ProximaNovaLight')
  src: url("/fonts/light/proxima-nova-light.woff2") format("woff2")
  src: url("/fonts/light/proxima-nova-light.woff") format("woff")
  src: url("/fonts/light/proxima-nova-light.ttf") format("truetype")
  font-weight: 300
  font-display: swap

@font-face
  font-family: "Proxima Nova"
  src: local('Proxima Nova,Proxima Nova Rg:style=Regular')
  src: local('Proxima Nova Regular')
  src: local('proxima-nova-regular')
  src: local('ProximaNovaRegular')
  src: url('/fonts/regular/proxima-nova-regular.woff2') format('woff2')
  src: url('/fonts/regular/proxima-nova-regular.woff') format('woff')
  src: url("/fonts/regular/proxima-nova-regular.ttf") format("truetype")
  font-weight: 400
  font-display: swap

@font-face
  font-family: "Proxima Nova"
  src: local('Proxima Nova,Proxima Nova Rg:style=Medium')
  src: local('Proxima Nova Medium')
  src: local('proxima-nova-medium')
  src: local('ProximaNovaMedium')
  src: url('/fonts/medium/proxima-nova-medium.woff2') format('woff2')
  src: url('/fonts/medium/proxima-nova-medium.woff') format('woff')
  font-weight: 500
  font-display: swap

@font-face
  font-family: "Proxima Nova"
  src: local('Proxima Nova,Proxima Nova Th:style=Semibold')
  src: local('Proxima Nova Semi Bold')
  src: local('proxima-nova-semi-bold')
  src: local('ProximaNovaSemiBold')
  src: url('/fonts/semi-bold/proxima-nova-semi-bold.woff2') format('woff2')
  src: url('/fonts/semi-bold/proxima-nova-semi-bold.woff') format('woff')
  src: url("/fonts/semi-bold/proxima-nova-semi-bold.ttf") format("truetype")
  font-weight: 600
  font-display: swap

@font-face
  font-family: "Proxima Nova"
  src: local('Proxima Nova,Proxima Nova Rg:style=Bold')
  src: local('Proxima Nova Bold')
  src: local('proxima-nova-bold')
  src: local('ProximaNovaBold')
  src: url('/fonts/bold/proxima-nova-bold.woff2') format('woff2')
  src: url('/fonts/bold/proxima-nova-bold.woff') format('woff')
  src: url("/fonts/bold/proxima-nova-bold.ttf") format("truetype")
  font-weight: 700
  font-display: swap

.primary-gradient
  background-image: linear-gradient(60deg, #C53232 0%, #7C36DD 100%)

.footer-primary-gradient
  background-image: linear-gradient(10deg, #C53232 0%, #7C36DD 100%)

.slant-orange-split
  min-height: 100vh
  background: linear-gradient(70deg, theme('colors.orange.100') 40%, #ffffff 40%)
  @media screen and ( max-width: 768px )
    min-height: calc(50vh + 5rem)
    height: calc(50vh + 5rem)
    background-size: 0%, 100% 100%

.btn
  @apply inline-block align-middle font-700 text-base leading-tight px-10 py-2 border rounded

.btn:focus
  @apply outline-none

.btn-orange
  @apply bg-orange border-orange text-white

.btn-orange:hover
  @apply bg-orange-500 text-white

.btn-orange:active
  @apply bg-orange-800 text-white

.swal-button
  @apply inline-block align-middle font-700 text-base leading-tight px-10 py-2 border rounded
  &:active
    @apply bg-orange-800 text-white
  &:focus
    box-shadow: none
  &:not(disabled):hover
    background-color: #FF7160 !important

.swal-button--confirm
  @apply bg-orange border-orange text-white

@tailwind utilities

@layer base
  ul
    @apply list-disc pl-10
  ol
    @apply list-decimal pl-10
  ul ul, ol ul
    @apply list-circle
  ol ol, ul ol
    @apply list-lower-latin

.assets
  img
    display: inline-block

.rich-text-content
  a
    @apply text-orange
  a:hover
    @apply underline
  pre
    white-space: pre-wrap
  table
    max-width: 100% !important
    width: 100% !important
    border-collapse: collapse
    border: 1px solid #ccc
  tr
    border-bottom: 1px solid #ccc
  th, td
    text-align: left
    padding: 4px
    border: 1px solid #ccc

#jsd-widget
  right: 0px
  margin-right: 0.5rem
  margin-button: 0.5rem
  z-index: 10!important

#phone:disabled
  background-color: #F3F4F7
  color: #999999
  cursor: default

.school-partner-form select
  background: url("https://image.flaticon.com/icons/png/128/118/118738.png") no-repeat right 0.75rem center
  background-size: 16px 16px
  padding: 0 15px !important

.school-partner-form textarea
  padding: 12px 15px !important

.thin-scroll-bar::-webkit-scrollbar
  @apply w-2

.thin-scroll-bar::-webkit-scrollbar-track
  @apply bg-grey-100 rounded-lg

.thin-scroll-bar::-webkit-scrollbar-thumb
  @apply bg-grey-500 rounded-lg

.thin-scroll-bar::-webkit-scrollbar-thumb:hover
  @apply bg-grey-600

.course-overview-card-stat-progress-circle > .rc-progress-circle-path
  transition: stroke-dasharray 1.2s ease 0s !important

.hero-gradient-red-purple
  background-image: linear-gradient(82.95deg, #BF3240 -6.56%, #7E36D9 122.43%)

.hero-gradient-white-orange
  background-image: linear-gradient(170deg, #FFEDEB 50%, #FFFFFF 64%)

.hero-gradient-purple-red
  background: linear-gradient(287.41deg, #BF3240 -8.7%, #B73252 24.1%, #7E36D9 100%)

.remove-margin
  margin: 0 !important

.wobble-animation
  animation: wobble 1s linear infinite

.introjs-nextbutton
    background-color: #FF5A43 !important
    color: #ffffff !important
    border: 1px solid #FF5A43 !important
    font-weight: 600 !important
    box-shadow: none !important
    text-shadow: none !important
    &:hover
      background-color: #FF7160 !important
      border: 1px solid #FF7160 !important
      color: #ffffff !important

.introjs-prevbutton
    background-color: #FFFFFF !important
    color: #FF5A43 !important
    border: 1px solid #FF5A43 !important
    font-weight: 600 !important
    &:hover
      background-color: #FFF6F5 !important
.introjs-tooltip
    min-width: 350px !important

.intro-tooltip-m-width
  @media screen and ( min-width: 768px )
    max-width: 100% !important
    min-width: 500px !important

.introjs-tooltiptext
    padding: 5px 10px !important

@keyframes wobble
  0%
    transform: rotate(0deg)
  25%
    transform: rotate(10deg)
  75%
    transform: rotate(-10deg)
  100%
    transform: rotate(0deg)

.floating-input
  input[type='text'], input[type='email']
    padding-left: 1rem !important
    padding-right: 1rem !important
    height: 3rem !important
    @apply relative h-12 w-full transition-all duration-200 ease-in-out p-2 px-4
    &:focus, &:not(&:placeholder-shown)
      @apply pt-6
      + label
        @apply text-xs text-grey-800 pointer-events-none mt-auto
    &:disabled
      @apply bg-grey-300 text-grey-700
      + label
        @apply text-grey-700
    + label
      @apply absolute top-0 left-0 flex cursor-text items-center p-2 px-4 transition-all duration-200 ease-in-out text-base mt-1 select-none

  .formbuilder-text
    @apply relative

.fine-uploader-dropzone-container.react-fine-uploader-gallery-dropzone
  min-height: 210px

#auth-modal .grecaptcha-badge
  visibility: hidden

.underlined-text
  text-decoration: underline
  text-decoration-color: orange
  text-decoration-thickness: 3px

.custom-fc-frame.fc-bottom-left
  left: 20px !important
  bottom: 20px !important

@keyframes ping
  0%
    transform: scale(0)
    opacity: 1
  75%, 100%
    transform: scale(1.5)
    opacity: 0

.animate-small-pulse
  animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite

.hero-gradient
  background: linear-gradient(82.95deg, #BF3240 -6.56%, #7E36D9 122.43%), #FF5A43

.shadow-2d
  box-shadow: 6px 6px 0px rgba(0, 0, 0, 0.1)

.shadow-2d-blue
  box-shadow: 5px 5px 0px 0px #D5ECFF

.decoration-1
  text-decoration-thickness: 1px

html
  scroll-behavior: smooth

.yellow-underline
  text-decoration: underline
  text-decoration-color: #FEBF05
  text-decoration-thickness: 3px

@media print
  .print-none
    display: none

li.react-multi-carousel-dot
  button
    width: 1rem
    height: 0.25rem
    border: 0.25rem #ffc2bb
    cursor: pointer
    background-color: #ffc2bb


  &.react-multi-carousel-dot--active
    button
      border: 0.25rem #FF5A43
      background-color: #FF5A43
.react-multi-carousel-list.custom-corousel-container
  .react-multiple-carousel__arrow
    background-color: #FF5A43
    opacity: 0.6
    border-radius: 5px
    &:hover
      background-color: #FF5A43
      opacity: 1
  .react-multiple-carousel__arrow--right
    right: 0

  .react-multiple-carousel__arrow--left
    left: 0

  button:focus
    outline: none

a.disabled
  pointer-events: none
  cursor: not-allowed

max-w-half
  max-width: 50%

// custom styles for the homepage hero section, to avoid  breaking the existing styles
.custom-home-h1
  font-size: 2.5rem
  line-height: 1.1
  @media screen and ( max-width: 1280px )
    font-size: 2.10rem
  @media screen and ( max-width: 1023px )
    font-size: 2rem
    line-height: 1.2
  @media screen and ( max-width: 640px )
    font-size: 1.5rem
  @media screen and ( max-width: 380px )
    font-size: 1.25rem
  @media screen and ( max-width: 320px )
    font-size: 1.2rem
